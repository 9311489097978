export const pageSearchParamsToUrlSearchParams = (
	searchParams: Record<string, string | string[] | undefined>,
): URLSearchParams => {
	const result = new URLSearchParams();
	for (const [key, value] of Object.entries(searchParams)) {
		if (Array.isArray(value)) {
			for (const v of value) {
				result.append(key, v);
			}
		} else {
			result.append(key, value ?? "");
		}
	}

	return result;
};

export const searchParamsToParsedUrlObject = (
	searchParams: URLSearchParams,
): Record<string, string | string[]> => {
	const result: Record<string, string | string[]> = {};

	for (const key of searchParams.keys()) {
		const value = searchParams.getAll(key);
		result[key] = value;
	}

	return result;
};

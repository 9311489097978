import type { ComponentPropsWithRef, ElementRef } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";

type Props = Omit<ComponentPropsWithRef<"div">, "children"> & {
	text: string;
};

/**
 * Ribbon that only accepts text as a child
 */
export const Ribbon = forwardRef<ElementRef<"div">, Props>(({ className, text }, ref) => (
	<div ref={ref} className={cn("absolute left-0 top-4 bg-white p-2 pl-3 font-bold", className)}>
		{text}
	</div>
));

Ribbon.displayName = "Ribbon";

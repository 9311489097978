// Based on shadcn/ui Sheet
"use client";

import * as DialogPrimitive from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";
import { cn } from "../../helpers/styles";

export type DialogProps = React.ComponentPropsWithoutRef<typeof DialogPrimitive.Root> & {
	defaultOpen?: boolean;
};

export const Dialog = ({
	defaultOpen,
	open: openProp,
	onOpenChange,
	children,
	...props
}: DialogProps): React.JSX.Element => {
	const [openState, setOpenState] = useState(!!defaultOpen);
	const [key, setKey] = useState(defaultOpen ? "open" : "closed");
	const pathname = usePathname();
	useEffect(() => {
		// Close the dialog when the pathname changes
		setOpenState(false);
	}, [pathname]);

	const open = openProp ?? openState;

	// We abuse the key to rerender the contents when the menu is open or closed
	// This is needed to keep the dialog animations in place
	useEffect(() => {
		if (open) {
			setKey("open");
		} else {
			setTimeout(() => setKey("closed"), 300);
		}
	}, [open]);

	useEffect(() => {
		setOpenState(!!defaultOpen);
	}, [defaultOpen]);

	return (
		<DialogPrimitive.Root
			{...props}
			key={key}
			defaultOpen={!!defaultOpen}
			open={open}
			onOpenChange={onOpenChange?.bind(props) ?? setOpenState}
		>
			{children}
		</DialogPrimitive.Root>
	);
};

export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogClose = DialogPrimitive.Close;

export const DialogPortal = ({
	...props
}: DialogPrimitive.DialogPortalProps): React.JSX.Element => <DialogPrimitive.Portal {...props} />;
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & { animatedIn?: boolean }
>(({ className, animatedIn, ...props }, ref) => {
	return (
		<DialogPrimitive.Overlay
			className={cn(
				"fixed inset-0 z-40 bg-gray-500 bg-opacity-15 data-[state=closed]:animate-out data-[state=closed]:fade-out-0",
				animatedIn && "data-[state=open]:animate-in data-[state=open]:fade-in-0",
				className,
			)}
			{...props}
			ref={ref}
		/>
	);
});
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const dialogVariants = cva(
	"fixed z-50 max-h-full gap-4 bg-white shadow-3 data-[state=closed]:animate-out data-[state=closed]:transition data-[state=closed]:ease-in-out duration-300",
	{
		variants: {
			side: {
				top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top",
				bottom: "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom",
				left: "inset-y-0 left-0 h-full w-96 max-w-full border-r sm:max-w-sm data-[state=closed]:slide-out-to-left",
				right:
					"inset-y-0 right-0 h-full w-96 max-w-full border-l sm:max-w-sm data-[state=closed]:slide-out-to-right",
				responsive:
					"max-md:inset-x-0 max-md:bottom-0 max-md:border-t max-md:data-[state=closed]:slide-out-to-bottom md:inset-y-0 md:right-0 md:h-full md:w-96 md:max-w-full md:border-l md:data-[state=closed]:slide-out-to-right",
			},
			size: {
				default: "",
			},
			animatedIn: {
				true: "data-[state=open]:animate-in data-[state=open]:transition data-[state=open]:ease-in-out",
				false: "animate-none",
			},
		},
		compoundVariants: [
			{
				side: "top",
				animatedIn: true,
				className: "data-[state=open]:slide-in-from-top",
			},
			{
				side: "bottom",
				animatedIn: true,
				className: "data-[state=open]:slide-in-from-bottom",
			},
			{
				side: "left",
				animatedIn: true,
				className: "data-[state=open]:slide-in-from-left",
			},
			{
				side: "right",
				animatedIn: true,
				className: "data-[state=open]:slide-in-from-right",
			},
			{
				side: "responsive",
				animatedIn: true,
				className:
					"max-md:data-[state=open]:slide-in-from-bottom md:data-[state=open]:slide-in-from-right",
			},
		],
		defaultVariants: {
			side: "left",
			animatedIn: true,
		},
	},
);

type DialogContentProps = React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> &
	Omit<VariantProps<typeof dialogVariants>, "animated"> & {
		slideInAnimation?: boolean;
	};

export const DialogContent = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Content>,
	DialogContentProps
>(({ side = "left", className, animatedIn, children, ...props }, ref) => (
	<>
		<DialogOverlay animatedIn={animatedIn ?? true} />
		<DialogPrimitive.Content
			ref={ref}
			className={cn(dialogVariants({ side, animatedIn: animatedIn ?? true }), className)}
			{...props}
		>
			{children}
		</DialogPrimitive.Content>
	</>
));

DialogContent.displayName = DialogPrimitive.Content.displayName;

export const DialogTitle = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => {
	return (
		<VisuallyHidden>
			<DialogPrimitive.Title className={cn(className)} {...props} ref={ref} />
		</VisuallyHidden>
	);
});

DialogTitle.displayName = DialogPrimitive.Title.displayName;

import type { Locale } from "@commerce-frontend/i18n/types";

type Money = {
	centAmount: number;
	currency: string;
};

export type MoneyParts = {
	currency: string;
	integer: string;
	decimal: string;
	fraction: string;
};

export const getMoneyParts = (money: Money, locale: Locale): MoneyParts => {
	const parts = new Intl.NumberFormat(locale, {
		minimumFractionDigits: 2,
		currency: money.currency,
		style: "currency",
	}).formatToParts(money.centAmount / 100);

	return {
		currency: parts.find((p) => p.type === "currency")?.value ?? "",
		integer: parts.find((p) => p.type === "integer")?.value ?? "",
		decimal: parts.find((p) => p.type === "decimal")?.value ?? "",
		fraction: parts.find((p) => p.type === "fraction")?.value ?? "",
	};
};

export const formatMoney = (money: Money, locale: Locale): string =>
	new Intl.NumberFormat(locale, {
		style: "currency",
		minimumFractionDigits: 2,
		currency: money.currency,
	}).format(money.centAmount / 100);

import type { ComponentPropsWithRef, ElementRef } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";

/**
 * A grid for displaying product cards
 */
export const ProductGrid = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("grid grid-cols-2 gap-4 lg:grid-cols-5", className)} {...props}>
				{children}
			</div>
		);
	},
);

ProductGrid.displayName = "ProductGrid";

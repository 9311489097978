import type { Locale } from "@commerce-frontend/i18n/types";
import { graphql } from "@commerce-frontend/types";
import { Ribbon } from "@commerce-frontend/ui/base/Ribbon/Ribbon";
import {
	ProductCard as ProductCardBase,
	ProductCardImage,
	ProductCardInfo,
	ProductCardInfoCategory,
	ProductCardInfoPrice,
	ProductCardInfoProductDescription,
	ProductCardInfoProductName,
} from "@commerce-frontend/ui/components/ProductCard/ProductCard";
import { BREAKPOINTS } from "@commerce-frontend/ui/tailwind.config";
import type { ResultOf } from "@graphql-typed-document-node/core";
import { useTranslations } from "next-intl";
import { Image, imagePlaceholder } from "~/components/Image/Image";
import { PriceTag } from "~/components/PriceTag/PriceTag";

type Props = {
	product: ResultOf<typeof ProductCardFragment>;
	className?: string;
	locale: Locale;
};

const ProductCardFragment = graphql(/* GraphQL */ `
	fragment ProductCard on Product {
		name
		slug
		neckline
		rawMaterial
		brand
		denier
		fit
		packSize

		ribbon {
			kind
			label
		}

		image {
			url
			alt
		}

		startingPrice {
			gross {
				...PriceTag
			}
		}

		primaryVariant {
			...ProductVariantAnalyticsFragment
			id
			sku
			availability
			price {
				net {
					...PriceTag
				}
				gross {
					...PriceTag
				}
			}
		}

		primaryCategory {
			...CategoryAnalyticsFragment
		}

		variants {
			...ProductVariantAnalyticsFragment
		}

		...ProductAnalyticsFragment
	}
`);

/**
 * Composition of ProductCard components containing a product
 */
export const ProductCard = ({ product, locale, className }: Props) => {
	const t = useTranslations("ProductBuySection");

	// TODO: Check if gross price should be shown for consumer website
	const price = product?.primaryVariant?.price?.gross ?? product?.startingPrice?.gross;
	const category = product.primaryCategory?.ancestors?.at(-1);
	const catName = category?.storyblokName || category?.name || "";

	const neckline = product.neckline ?? "";
	const rawmaterial = product.rawMaterial ?? "";
	const brand = product.brand ?? "";
	const denier = product.denier ?? "";
	const fit = product.fit ?? "";
	const packsize = product.packSize ? t("pack-size", { size: product.packSize }) : "";

	const underline = [brand, rawmaterial, denier, neckline, fit, packsize]
		.filter(Boolean)
		.join(", ");

	return (
		<ProductCardBase
			aria-label={product.name}
			aria-describedby={`product-card-${product.slug}`}
			className={className}
		>
			<ProductCardImage>
				<Image
					src={product.image?.url ?? imagePlaceholder}
					alt={product.image?.alt ?? product.name}
					width={230}
					height={230}
					sizes={`(min-width: ${BREAKPOINTS.lg}) 20vw, (min-width: ${BREAKPOINTS.xl}) 230px, 50vw`}
					className="w-full object-fill"
				/>

				{product.ribbon?.label ? <Ribbon text={product.ribbon?.label} /> : undefined}
			</ProductCardImage>
			<ProductCardInfo>
				<ProductCardInfoPrice>
					{price ? <PriceTag size="xs" price={price} locale={locale} /> : null}
				</ProductCardInfoPrice>

				{catName ? <ProductCardInfoCategory>{catName}</ProductCardInfoCategory> : null}

				<ProductCardInfoProductName>{product.name}</ProductCardInfoProductName>

				{underline ? (
					<ProductCardInfoProductDescription>{underline}</ProductCardInfoProductDescription>
				) : null}
			</ProductCardInfo>
		</ProductCardBase>
	);
};

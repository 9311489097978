import type { ComponentPropsWithRef, ElementRef } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";

export const ProductCard = forwardRef<ElementRef<"article">, ComponentPropsWithRef<"article">>(
	({ children, className, ...props }, ref) => {
		return (
			<article
				ref={ref}
				className={cn(
					"group/productcard relative flex flex-col gap-sm text-inherit transition-all",
					className,
				)}
				{...props}
			>
				{children}
			</article>
		);
	},
);

ProductCard.displayName = "ProductCard";

export const ProductCardImage = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div
				ref={ref}
				className={cn(
					"relative flex items-center aspect-4/5 overflow-hidden rounded-xs shadow-1 group-hover/productcard:shadow-2 motion-safe:transition-shadow",
					className,
				)}
				{...props}
			>
				{children}
			</div>
		);
	},
);

ProductCardImage.displayName = "ProductCardImage";

export const ProductCardInfo = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("mb-4 flex-1 gap-2 px-2", className)} {...props}>
				{children}
			</div>
		);
	},
);

ProductCardInfo.displayName = "ProductCardInfo";

export const ProductCardInfoProductName = forwardRef<ElementRef<"h3">, ComponentPropsWithRef<"h3">>(
	({ children, className, ...props }, ref) => {
		return (
			<h3 ref={ref} className={cn("line-clamp-2 text-base font-semibold", className)} {...props}>
				{children}
			</h3>
		);
	},
);

ProductCardInfoProductName.displayName = "ProductCardInfoProductName";

export const ProductCardInfoProductDescription = forwardRef<
	ElementRef<"p">,
	ComponentPropsWithRef<"p">
>(({ children, className, ...props }, ref) => {
	return (
		<p ref={ref} className={cn("text-sm line-clamp-3 text-gray-600", className)} {...props}>
			{children}
		</p>
	);
});

ProductCardInfoProductDescription.displayName = "ProductCardInfoProductDescription";

export const ProductCardInfoPrice = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("flex text-3xl font-bold", className)} {...props}>
				{children}
			</div>
		);
	},
);

ProductCardInfoPrice.displayName = "ProductCardInfoPrice";

export const ProductCardInfoCategory = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div
				ref={ref}
				className={cn("flex text-xs font-semibold text-brand-700", className)}
				{...props}
			>
				{children}
			</div>
		);
	},
);

ProductCardInfoCategory.displayName = "ProductCardInfoCategory";

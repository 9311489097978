import type { Locale } from "@commerce-frontend/i18n/types";
import type { PriceTagFragment } from "@commerce-frontend/types";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { cva } from "class-variance-authority";
import type { HTMLAttributes } from "react";
import { getMoneyParts } from "~/lib/helpers";
import type { CurrencyAlign, Size } from "../PriceTag";

export type Variant = "default" | "discount";

type Props = {
	overline?: string;
	currencyAlign?: CurrencyAlign;
	size: Size;
	price: PriceTagFragment;
	variant: Variant;
	locale: Locale;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Component that renders a price with fixed spacing for numbers
 */
export const Price = ({ overline, size = "lg", currencyAlign, price, variant, locale }: Props) => {
	const { currency, integer, fraction } = getMoneyParts(price, locale);
	const integerDigits = integer ? integer.toString().split("") : [];
	const fractionDigits = fraction ? fraction.toString().split("") : [];
	const isOneLastNumber = integer.toString().endsWith("1");

	return (
		<div
			className={
				variant === "default" ? cn(priceVariants({ size })) : cn(discountPriceVariants({ size }))
			}
		>
			{overline && <span className={cn(overlineVariants({ size }))}>{overline}</span>}
			<span
				className={cn(
					variant === "discount" &&
						// Diagonal strikethrough
						"relative before:absolute before:top-1/2 before:left-0 before:w-[calc(100%+0.2em)] before:z-10 before:h-[4px] before:bg-black before:rounded-[4px] before:[transform:translateX(-0.12em)_translateY(0em)_rotate(-20deg)] before:origin-center before:content-['']",
				)}
			>
				{currencyAlign && currencyAlign === "left" && (
					<span className="relative text-[0.6em]">{currency}</span>
				)}
				{integerDigits.map((number, index) => (
					<FormattedNumber
						key={`int-${number}-${index}`}
						number={number}
						previousNumber={integerDigits[index - 1]}
					/>
				))}
				<span className="invisible">.</span>
				<span className={cn(fractionVariants({ isOneLastNumber }))}>
					{fractionDigits.map((number, index) => (
						<FormattedNumber
							key={`frac-${number}-${index}`}
							number={number}
							previousNumber={fractionDigits[index - 1]}
						/>
					))}
					{currencyAlign && currencyAlign === "right" && <span>{currency}</span>}
				</span>
			</span>
		</div>
	);
};

type FormattedNumberProps = {
	number: string;
	previousNumber: string;
};

/**
 * Component that formats every number "1" by adding a class to the number
 * to adjust the spacing
 */
const FormattedNumber = ({ number, previousNumber }: FormattedNumberProps) => {
	let className = "";

	if (number === "1") {
		className = "-mr-[0.10em] -ml-[0.05em]";
	}

	const adjustSpacingForNumber =
		(previousNumber === "7" || previousNumber === "5" || previousNumber === "4") && number === "1";

	if (adjustSpacingForNumber) {
		let wrapperClassName = "";
		if (previousNumber === "7") wrapperClassName = "-ml-[0.03em]";
		if (previousNumber === "5") wrapperClassName = "-ml-[0.05em]";
		if (previousNumber === "4") wrapperClassName = "-ml-[0.06em]";

		return (
			<span className={wrapperClassName}>
				<span className={className}>{number}</span>
			</span>
		);
	}

	return <span className={className}>{number}</span>;
};

export const priceVariants = cva([""], {
	variants: {
		size: {
			lg: "text-[7.5rem]",
			md: "text-[6rem]",
			sm: "text-[4.5rem]",
			xs: "text-[2.75rem]",
		},
	},
});

export const discountPriceVariants = cva(["text-gray-500 mb-[0.46em]"], {
	variants: {
		size: {
			lg: "text-[4.25rem]",
			md: "text-[3.25rem]",
			sm: "text-[2.75rem]",
			xs: "text-[1.625rem]",
		},
	},
});

export const fractionVariants = cva(["relative text-[0.6em] -top-[0.475em]"], {
	variants: {
		isOneLastNumber: {
			true: "-ml-[0.470em]",
			false: "-ml-[0.475em]",
		},
	},
});

export const overlineVariants = cva(["absolute top-0 font-semibold"], {
	variants: {
		size: {
			lg: "display-xs",
			md: "text-xl",
			sm: "text-md",
			xs: "text-xs",
		},
	},
});

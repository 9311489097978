import { locales } from "@commerce-shared/site-config/src/constants";
import type { CountryCode, Language } from "./types";

/**
 * Interpolate a string with values
 * @example interpolate("Hello {name}", { name: "John" }) // "Hello John"
 * @param template The template to interpolate
 * @param values An object with values to replace
 * @returns The interpolated label
 */
export const interpolate = (template: string, values: Record<string, string | number>): string => {
	let message = template;

	if (Object.keys(values).length > 0) {
		Object.entries(values).forEach(([key, value]) => {
			message = message.replace(`{${key}}`, value.toString());
		});
	}

	return message;
};

export const countryOf = (locale: string): CountryCode =>
	locale.split("-")[1]?.toUpperCase() as CountryCode;
export const languageOf = (locale: string): Language => locale.split("-")[0] as Language;

export const languageDisplayName = (locale: string): string => {
	const result =
		new Intl.DisplayNames([locale], {
			type: "language",
		}).of(languageOf(locale)) || "";

	return camelCase(result);
};

export const countryDisplayName = (country: CountryCode, language: Language) => {
	const regionNames = new Intl.DisplayNames([language], { type: "region" });
	const countryName = regionNames.of(country);

	return countryName ?? country;
};

export const camelCase = (value: string): string => {
	const [firstLetter, ...rest] = value;
	return firstLetter.toUpperCase() + rest.join("");
};

export function normalizeLocale<T extends string>(locale: T): T {
	const parts = locale.split("-");
	if (parts.length === 1) return locale;
	return `${parts[0]}-${parts[1].toUpperCase()}` as T;
}

type PartialContext = {
	locale: string;
};

export function normalizeContext<T extends PartialContext>(context: T): T {
	return {
		...context,
		locale: normalizeLocale(context.locale),
	};
}

/**
 * Removes the locale from the path
 *
 * @example removeLocaleFromPath("/nl-nl/product") // "/product"
 */
export const removeLocaleFromPath = (path: string): string => {
	const lowercaseLocales = locales.map((locale) => locale.toLowerCase());
	const localesPattern = lowercaseLocales.join("|");
	const localesRegex = new RegExp(`^/(${localesPattern})(/|$)`);

	return path.replace(localesRegex, "/");
};

import type { Locale } from "@commerce-frontend/i18n/types";
import type { PriceTagFragment } from "@commerce-frontend/types";
import { graphql } from "@commerce-frontend/types";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { cva } from "class-variance-authority";
import type { HTMLAttributes } from "react";
import { Price } from "./Price/Price";

graphql(/* GraphQL */ `
	fragment PriceTag on Money {
		centAmount
		currency
	}
`);

export type Size = "lg" | "md" | "sm" | "xs";
export type CurrencyAlign = "left" | "right";

type Props = {
	overline?: string;
	currencyAlign?: CurrencyAlign;
	size: Size;
	price: PriceTagFragment;
	discountOverline?: string;
	discountPrice?: PriceTagFragment;
	locale: Locale;
} & HTMLAttributes<HTMLDivElement>;

export const PriceTag = ({
	className,
	overline,
	discountOverline,
	size = "lg",
	currencyAlign,
	price,
	discountPrice,
	locale,
	...attributes
}: Props) => {
	return (
		<div className={cn(priceTagVariants({ size }), className)} {...attributes} aria-label="price">
			<Price
				overline={overline}
				currencyAlign={currencyAlign}
				size={size}
				price={price}
				variant="default"
				locale={locale}
			/>
			{discountPrice?.centAmount && discountPrice.centAmount > 0 && (
				<Price
					overline={discountOverline}
					currencyAlign={currencyAlign}
					size={size}
					price={discountPrice}
					variant="discount"
					locale={locale}
				/>
			)}
		</div>
	);
};

export const priceTagVariants = cva(["relative flex items-center font-texGyreHeros font-bold"], {
	variants: {
		size: {
			lg: "gap-5xl leading-tight",
			md: "gap-4xl leading-snug",
			sm: "gap-3xl leading-normal",
			xs: "gap-2xl leading-relaxed",
		},
	},
});

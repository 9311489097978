import type { Locale } from "@commerce-frontend/i18n/types";
import { useParams } from "next/navigation";
import type { Currency } from "~/lib/store-config/context";
import { useStoreConfig } from "~/lib/store-config/context";

export const useStoreContext = (): { storeKey: string; currency: Currency; locale: Locale } => {
	const { locale: localeParam } = useParams();
	const storeConfig = useStoreConfig();
	const locale =
		storeConfig.locales.find((locale) => locale === localeParam) ?? storeConfig.defaultLocale;

	const storeContext = {
		storeKey: storeConfig.storeKey,
		currency: storeConfig.currency,
		locale,
	};

	return storeContext;
};

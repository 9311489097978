"use client";
import { usePathname, useRouter } from "@commerce-frontend/i18n/navigation";
import type { Locale } from "@commerce-frontend/i18n/types";
import { Button } from "@commerce-frontend/ui/base/Button";
import { pruneObject } from "@labdigital/toolkit";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useTranslations } from "next-intl";
import { useParams, useSearchParams } from "next/navigation";
import { type ComponentPropsWithoutRef, useOptimistic, useTransition } from "react";
import { searchParamsToParsedUrlObject } from "~/lib/search-params";
import { PageButton } from "./buttons";

type Props = {
	locale: Locale;
	currentPage: number;
	totalPages: number;
} & ComponentPropsWithoutRef<"nav">;

export const range = ({ start, end }: { start: number; end: number }) =>
	Array.from({ length: end - start }, (_, i) => start + i);

/**
 * A pagination component used for listings.
 *
 * @example
 * Consider the current page to be page 5 of 10, then the page range will be:
 * ```
 * < 1 ... 4 [5] 6 ... 10 >
 * ```
 */
export function Pagination({ locale, currentPage, totalPages, ...props }: Props) {
	const [optimisticCurrentPage, setOptimisticCurrentPage] = useOptimistic(currentPage);
	const [isPending, startTransition] = useTransition();
	const router = useRouter();
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const params = useParams();
	const t = useTranslations("Pagination");

	const href = {
		pathname: pathname as "/",
		params: {
			...params,
			slug: Array.isArray(params.slug) ? params.slug.slice(1) : params.slug, // We want to remove the filtered query parameters
		},
	};

	// e.g. 4 [5] 6 if 5 is the current page
	const pageRange = range({
		start: Math.max(2, optimisticCurrentPage - 1),
		end: Math.min(totalPages, optimisticCurrentPage + 2),
	});

	const handlePageChange = (page: number) => {
		setOptimisticCurrentPage(page);
		startTransition(() => {
			setTimeout(() => {
				window.scrollTo({ top: 0, behavior: "smooth" });
			}, 100);
			router.push(
				{
					...href,
					query: pruneObject({
						...searchParamsToParsedUrlObject(searchParams),
						page: page > 1 ? page : undefined,
					}),
				},
				{ scroll: false, locale },
			);
		});
	};

	return (
		<nav {...props} data-pending={isPending ? "" : undefined}>
			<ul className="flex items-center justify-center gap-2">
				{/* Previous button */}
				<li className="mr-auto">
					{optimisticCurrentPage !== 1 ? (
						<Button
							variant="secondaryColor"
							onClick={() => handlePageChange(optimisticCurrentPage - 1)}
							aria-label={t("previous-page")}
						>
							<ArrowLeft />
							<span className="hidden sm:inline">{t("previous-page")}</span>
						</Button>
					) : (
						<Button variant="secondaryColor" disabled aria-label={t("previous-page")}>
							<ArrowLeft />
							<span className="hidden sm:inline">{t("previous-page")}</span>
						</Button>
					)}
				</li>
				{/* Always show the first page */}
				<li>
					<PageButton
						page={1}
						currentPage={optimisticCurrentPage}
						onClickLink={() => handlePageChange(1)}
					/>
				</li>
				{/* Show ellipses */}
				{pageRange[0] > 2 && (
					<li className="border-t-4 border-transparent">
						<span className="flex h-full place-items-center p-2 text-sm text-blue-500">...</span>
					</li>
				)}
				{/* Loop through the pages in range */}
				{pageRange.map((page) => (
					<li key={page} className={pageRangeClassName(page, pageRange, totalPages)}>
						<PageButton
							page={page}
							currentPage={optimisticCurrentPage}
							onClickLink={() => handlePageChange(page)}
						/>
					</li>
				))}
				{/* Show ellipses */}
				{pageRange.length > 0 && pageRange.slice(-1)[0] !== totalPages - 1 && (
					<li className="border-t-4 border-transparent">
						<span className="flex h-full place-items-center p-2 text-sm text-blue-500">...</span>
					</li>
				)}
				{/* Always show the last page */}
				{totalPages === 1 ? null : (
					<li>
						<PageButton
							page={totalPages}
							currentPage={optimisticCurrentPage}
							onClickLink={() => handlePageChange(totalPages)}
						/>
					</li>
				)}
				{/* Next button */}
				<li className="ml-auto">
					{optimisticCurrentPage !== totalPages ? (
						<Button
							variant="secondaryColor"
							onClick={() => handlePageChange(optimisticCurrentPage + 1)}
							aria-label={t("next-page")}
						>
							<span className="hidden sm:inline">{t("next-page")}</span>
							<ArrowRight />
						</Button>
					) : (
						<Button variant="secondaryColor" disabled aria-label={t("next-page")}>
							<span className="hidden sm:inline">{t("next-page")}</span>
							<ArrowRight />
						</Button>
					)}
				</li>
			</ul>
		</nav>
	);
}

function pageRangeClassName(page: number, pageRange: number[], totalPages: number) {
	/**
	 * For the mobile view, we want to hide specific pages because of the limited space.
	 * The logic for this (below) isn't pretty, but it is quite easy to understand.
	 * First off, only hide pages when there are more than 3 in range.
	 */
	if (pageRange.length === 3) {
		// Special case: never hide the second, and second-to-last page.
		if (page !== 2 && page !== totalPages - 1) {
			// In all other cases, hide the previous and next pages of the current range.
			if (page !== pageRange[1]) {
				return "max-sm:hidden";
			}
		}
	}
	return "";
}

import type { LinkProps } from "@commerce-frontend/ui/primitives/Link/Link";

type Link = LinkProps["href"];

export const productDetailHref = (slug: string, sku?: string | null): Link => ({
	pathname: "/product/[slug]",
	params: {
		slug,
	},
	query: sku
		? {
				sku,
			}
		: undefined,
});

import { useLayoutEffect, useState } from "react";

export const useSafeServerLoading = <T extends { isLoading: boolean }>(result: T): T => {
	const [extraLoading, setExtraLoading] = useState<boolean>(true);
	useLayoutEffect(() => {
		setExtraLoading(false);
	}, []);

	return {
		...result,
		isLoading: result.isLoading || extraLoading,
	};
};

import type { UrlObject } from "node:url";
import { usePathname } from "@commerce-frontend/i18n/navigation";
import { Button } from "@commerce-frontend/ui/base/Button";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { pruneObject } from "@labdigital/toolkit";
import { useParams, useSearchParams } from "next/navigation";
import { searchParamsToParsedUrlObject } from "~/lib/search-params";

// TODO: Get rid of this export
export const PageButton = ({
	page,
	currentPage,
	onClickLink,
}: {
	page: number;
	currentPage: number;
	onClickLink: () => void;
}) => {
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const params = useParams();

	return (
		<Button
			type="button"
			variant="tertiary"
			className={cn(
				"text-sm flex h-full min-w-11 place-items-center px-4 py-2 font-medium hover:border-t-4 hover:border-orange-dark-200 hover:bg-gray-50 hover:font-bold hover:text-gray-800",
				page === currentPage
					? "border-t-4 border-orange-dark-200 bg-gray-50 font-bold text-gray-800 disabled:text-gray-800"
					: "border-t-4 border-transparent text-brand",
			)}
			disabled={page === currentPage}
			href={
				{
					pathname,
					params: {
						...params,
						slug: Array.isArray(params.slug) ? params.slug.slice(1) : params.slug, // We want to remove the filtered search parameters
					},
					query: pruneObject({
						...searchParamsToParsedUrlObject(searchParams),
						page: page > 1 ? page : undefined,
					}),
				} as unknown as { pathname: "/" } & Omit<UrlObject, "pathname">
			}
			onClick={onClickLink}
			scroll={false}
		>
			{page}
		</Button>
	);
};
